import { Text } from '@trmediaab/zebra';

interface ClampedTextProps extends React.ComponentProps<typeof Text> {
  lines: number;
}

const ClampedText = ({ lines, children, ...props }: ClampedTextProps) => (
  <Text
    css={`
      overflow: hidden;
      text-overflow: ellipsis;
      > * {
        display: none;

        &:first-child {
          display: block;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: ${lines};
        }
      }
    `}
    {...props}
  >
    {children}
  </Text>
);

export default ClampedText;
