import { Anchor, List, Position } from '@trmediaab/zebra';
import Link from 'next/link';

import type { NavLinkProps } from '~/constants/navLinks';
import { NAV_LINKS, whiteUnderlineHoverCss } from '~/constants/navLinks';

interface SiteNavProps {
  navOpen: boolean;
}

const SiteNav = ({ navOpen }: SiteNavProps) => (
  <Position
    position={['absolute', null, 'relative']}
    top={['65px', null, '2px', '5px']}
    width={['100%', null, 'initial']}
    left="0"
    display={[navOpen ? 'block' : 'none', null, 'initial']}
  >
    <List display="flex" flexDirection={['column', null, 'row']}>
      {NAV_LINKS.map(({ name, href }: NavLinkProps) => (
        <List.listItem
          key={href}
          borderTop={['1px', null, '0']}
          borderTopStyle={['solid', null, null, null]}
          borderTopColor={['black', null, null, null]}
        >
          <Anchor
            as={Link}
            href={href}
            color="white"
            textDecoration="none"
            pl={['16px', null, '15px', '18px', '24px']}
            fontSize={['inherit', null, '15px', 'inherit']}
            fontWeight={['400', null, '500']}
            height="50px"
            backgroundColor={['#222', null, 'transparent']}
            display="flex"
            alignItems="center"
            css={whiteUnderlineHoverCss}
          >
            {name}
          </Anchor>
        </List.listItem>
      ))}
    </List>
  </Position>
);

export default SiteNav;
