import { Box, Container, Position } from '@trmediaab/zebra';
import { useRouter } from 'next/router';
import { useCallback, useState } from 'react';

import Logo from '~/components/Logo';
import SiteNav from '~/components/SiteNav';
import BurgerButton from '~/components/SiteNav/BurgerButton';
import useRouterEvents from '~/hooks/useRouterEvents';

const SiteHeader = () => {
  const [navOpen, setNavOpen] = useState(false);
  const router = useRouter();
  const location = router.pathname;

  const closeNav = useCallback(() => setNavOpen(false), [setNavOpen]);

  useRouterEvents({ onComplete: closeNav });

  return (
    <Position
      as="header"
      bg={[
        'background.secondary',
        null,
        null,
        location === '/' ? 'transparent' : 'background.secondary',
      ]}
      top="0"
      color="white"
      zIndex="siteHeader"
      width="100%"
      position={[
        'sticky',
        null,
        null,
        location === '/' ? 'absolute' : 'static',
      ]}
    >
      <Container
        height={['65px', null, null, '122px']}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        margin="auto"
      >
        <Logo title="TRmedia" height={['26px', null, null, '50px']} />

        <Box display={['block', null, 'none']} width="18px">
          <Position.relative
            sx={{ transition: 'margin-left 0.3s', willChange: 'margin-left' }}
          >
            <BurgerButton
              navOpen={navOpen}
              onClick={() => setNavOpen(!navOpen)}
            />
          </Position.relative>
        </Box>

        <SiteNav navOpen={navOpen} />
      </Container>
    </Position>
  );
};

export default SiteHeader;
