import { Anchor } from '@trmediaab/zebra';
import { createLocalStyledIcon } from '@trmediaab/zebra-icons';
import Link from 'next/link';

import { default as LogoIcon } from './logo.svg';

const IconComponent = createLocalStyledIcon(LogoIcon);

interface LogoProps extends React.ComponentProps<typeof IconComponent> {
  linked?: boolean;
}

const Logo = ({ linked = true, ...props }: LogoProps) =>
  linked ? (
    <Anchor as={Link} href="/">
      <IconComponent {...props} />
    </Anchor>
  ) : (
    <IconComponent {...props} />
  );
export default Logo;
