import {
  Anchor,
  Box,
  Button,
  Container,
  FlexBox,
  Heading,
  List,
  Rhythm,
  Text,
} from '@trmediaab/zebra';
import Link from 'next/link';

import Logo from '~/components/Logo';
import type { NavLinkProps } from '~/constants/navLinks';
import {
  EXTERNAL_FOOTER_LINKS,
  INTERNAL_FOOTER_LINKS,
  whiteOpacityHoverCss,
  whiteUnderlineHoverCss,
} from '~/constants/navLinks';
import ArrowRightIcon from '~/icons/ArrowRight';

import ExternalLink from '../ExternalLink';

const SiteFooter = () => (
  <Box as="footer" bg="background.secondary" color="white" py="5">
    <Container margin="auto">
      <Rhythm between="7">
        <Logo height={['25px', null, null, '50px']} />
        <FlexBox flexDirection={{ _: 'column', md: 'row' }}>
          <FlexBox
            flex={[1, null, null, 2]}
            ml={[0, null, null, '105px']}
            mb={['40px', null, null, 0]}
            flexDirection="column"
          >
            <Rhythm between="5">
              <Box>
                <Text.p fontSize="2">TR Media AB</Text.p>
                <Text.p fontSize="2">Box 11139, 161 11 Bromma</Text.p>
              </Box>

              <Box>
                <Text.p fontSize="2">
                  Telefon:{' '}
                  <Anchor
                    href="tel:+46856482000"
                    variant="matchText"
                    textDecoration="none"
                    css={whiteUnderlineHoverCss}
                  >
                    08-564 820 00
                  </Anchor>
                </Text.p>
                <Text.p fontSize="2">
                  E-post:{' '}
                  <Anchor
                    href="mailto:info@trmedia.se"
                    variant="matchText"
                    css={whiteOpacityHoverCss}
                  >
                    info@trmedia.se
                  </Anchor>
                </Text.p>
              </Box>

              <Box>
                <Text.p fontSize="2">Organisationsnummer: 556078-5114</Text.p>
              </Box>

              <Box>
                <Text.p fontSize="2">
                  <ArrowRightIcon mr="5px" />
                  Besök oss
                </Text.p>
                <Text.p fontSize="2">Köpsvängen 26, Bromma</Text.p>
              </Box>
            </Rhythm>
          </FlexBox>

          <FlexBox
            flex={[1, null, null, 1]}
            mb={['40px', null, null, 0]}
            px={[null, null, '20px']}
            flexDirection="column"
          >
            <List>
              {INTERNAL_FOOTER_LINKS.map(({ name, href }: NavLinkProps) => (
                <List.listItem key={href} fontSize="2" lineHeight="26px">
                  <Anchor
                    as={Link}
                    href={href}
                    color="white"
                    textDecoration="none"
                    css={whiteUnderlineHoverCss}
                  >
                    {name}
                  </Anchor>
                </List.listItem>
              ))}
              {process.env.NEXT_PUBLIC_COOKIE_CONSENT_ENABLED === 'true' && (
                <List.listItem fontSize="2" lineHeight="26px">
                  <Button
                    fontWeight="regular"
                    css={whiteUnderlineHoverCss}
                    onClick={() => {
                      window.OneTrust && window.OneTrust.ToggleInfoDisplay();
                    }}
                  >
                    Cookie-inställningar
                  </Button>
                </List.listItem>
              )}
            </List>
          </FlexBox>

          <FlexBox flex={[1, null, null, 1]} flexDirection="column">
            <Rhythm between="5">
              <Heading.h5>Kundtjänst</Heading.h5>

              <List>
                {EXTERNAL_FOOTER_LINKS.map(({ name, href }: NavLinkProps) => (
                  <List.listItem key={href} fontSize="2" lineHeight="26px">
                    <ExternalLink
                      icon
                      href={href}
                      color="white"
                      textDecoration="none"
                      css={`
                        & svg {
                          color: #777;
                        }
                        &:hover,
                        &:active,
                        &:focus {
                          text-decoration: underline;
                          color: white;
                          svg {
                            color: #aaa;
                          }
                        }
                        &:visited {
                          color: white;
                        }
                      `}
                    >
                      {name}
                    </ExternalLink>
                  </List.listItem>
                ))}
              </List>
            </Rhythm>
          </FlexBox>
        </FlexBox>
      </Rhythm>
    </Container>
  </Box>
);

export default SiteFooter;
