import { Box, FlexBox, Text } from '@trmediaab/zebra';

import SiteHeader from '~/components/SiteHeader';

import SiteFooter from '../SiteFooter';

interface SiteLayoutProps {
  children: React.ReactNode;
}

const SiteLayout = ({ children }: SiteLayoutProps) => (
  <Text.root
    display="flex"
    css={`
      min-height: 100vh;
      /* mobile viewport bug fix */
      min-height: -webkit-fill-available;
      flex-direction: column;
    `}
  >
    <SiteHeader />
    <FlexBox flex="1" flexDirection="column">
      <Box flex="1">{children}</Box>
      <SiteFooter />
    </FlexBox>
  </Text.root>
);

export default SiteLayout;
