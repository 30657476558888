import { useRouter } from 'next/router';
import { useEffect } from 'react';

type Callback = () => void;

// eslint-disable-next-line @typescript-eslint/no-empty-function
const noop: Callback = () => {};

export default function useRouterEvents({
  onStart = noop,
  onComplete = noop,
  onError = noop,
}: {
  onStart?: Callback;
  onComplete?: Callback;
  onError?: Callback;
}) {
  const router = useRouter();

  useEffect(() => {
    router.events.on('routeChangeStart', onStart);
    router.events.on('routeChangeComplete', onComplete);
    router.events.on('routeChangeError', onError);

    return () => {
      router.events.off('routeChangeStart', onStart);
      router.events.off('routeChangeComplete', onComplete);
      router.events.off('routeChangeError', onError);
    };
  }, [onComplete, onError, onStart, router.events]);
}
