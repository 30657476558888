import { Anchor, Box, Heading, Text } from '@trmediaab/zebra';
import Link from 'next/link';

import ClampedText from '~/components/ClampedText';
import CloudinaryImage from '~/components/CloudinaryImage';
import type { Teaser } from '~/types/articleList';
import { getArticleUrl, getTeaserMedia } from '~/utils/articleUtils';

interface ArticleListProps {
  article: Teaser;
  tileColor?: string;
}

const ArticleItem = (props: ArticleListProps) => {
  const {
    teaser_headline: teaserHeadline,
    headline,
    teaser,
    preamble,
  } = props.article;
  const headlineText = teaserHeadline || headline;
  const teaserText = teaser || preamble;
  const backgroundColor = props.tileColor ?? 'greys.0';

  return (
    <Anchor
      as={Link}
      href={getArticleUrl(props.article)}
      overflow="hidden"
      flex="1 0 265px"
      display="flex"
      flexDirection="column"
      bg={backgroundColor}
      textDecoration="none"
      sx={{ position: 'relative', fontSize: [1, 2] }}
    >
      <CloudinaryImage
        image={getTeaserMedia(props.article)}
        ratio={[175, 100]}
        width={320}
        fallback
      />
      <Box px="3" pt={[3, null, '3,5']} pb="2,5" flex="1">
        <ClampedText lines={4}>
          <Text color="black">
            <Heading.h3>{headlineText}</Heading.h3>
            {teaserText && <Text.p mt="2,5">{teaserText}</Text.p>}
          </Text>
        </ClampedText>
      </Box>
      <Text ml="3" mb="3,5" fontWeight="medium" textDecoration="underline">
        Läs mer
      </Text>
    </Anchor>
  );
};

export default ArticleItem;
