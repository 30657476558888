import { Anchor, FlexBox } from '@trmediaab/zebra';

import External from '~/icons/External';

interface ExternalLinkProps extends React.ComponentProps<typeof Anchor> {
  href: string;
  icon?: boolean;
  newTab?: boolean;
}

const ExternalLink = ({
  href,
  icon = false,
  newTab = true,
  children,
  ...props
}: ExternalLinkProps) => (
  <Anchor
    href={href}
    target={newTab ? '_blank' : undefined}
    rel={newTab ? 'noopener noreferrer' : 'undefined'}
    {...props}
  >
    {icon ? (
      <FlexBox alignItems="center">
        <External mr={[2, null, '2,5']} mt="1px" /> {children}
      </FlexBox>
    ) : (
      children
    )}
  </Anchor>
);

export default ExternalLink;
