import { Box, Position } from '@trmediaab/zebra';
import { cloudinary } from '@trmediaab/zebra-utils';
import type { ImageProps } from 'next/legacy/image';
import Image from 'next/legacy/image';
import { useCallback } from 'react';

import type { ArchiveImage, AuthorImage } from '~/types/article';
import { getImageAlt } from '~/utils/articleUtils';

import Logo from '../Logo';

type WrapperProps = React.ComponentProps<typeof Box>;

interface CloudinaryImageProps
  extends Omit<ImageProps, 'src' | 'width' | 'height'> {
  image?: Nullable<ArchiveImage> | Nullable<AuthorImage>;
  width: number;
  ratio: [number, number];
  transforms?: string[];
  fallback?: boolean;
  wrapperProps?: WrapperProps;
}

// TODO sizes
const CloudinaryImage = (props: CloudinaryImageProps) => {
  const {
    image,
    width,
    ratio,
    transforms = [],
    layout = 'responsive',
    objectFit,
    fallback = true,
    wrapperProps,
  } = props;
  const height = (width / ratio[0]) * ratio[1];

  const loader = useCallback(
    ({
      src,
      width,
      quality,
    }: {
      src: string;
      width: number;
      quality?: number;
    }) => {
      let allTransforms = [`q_${quality || 'auto'}`, ...transforms];
      if (image != null && 'focus_point' in image) {
        allTransforms = [
          ...allTransforms,
          ...cloudinary.getFocusGravity(image.focus_point),
        ];
      }
      return cloudinary.getUrl(src, width, ratio, allTransforms);
    },
    [image, ratio, transforms],
  );

  if (image == null) {
    return fallback ? (
      <Box bg="background.dark" maxWidth="100%" {...wrapperProps}>
        <Position.relative
          width="100%"
          paddingBottom={`${(ratio[1] / ratio[0]) * 100}%`}
        >
          <Position.absolute
            top="0"
            left="0"
            width="100%"
            height="100%"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Logo width="60%" linked={false} />
          </Position.absolute>
        </Position.relative>
      </Box>
    ) : null;
  } else {
    return (
      <Box {...wrapperProps}>
        <Image
          src={'cdn_id' in image ? image.cdn_id : image.image_id}
          alt={getImageAlt(image)}
          loader={loader}
          layout={layout}
          width={width}
          height={height}
          objectFit={objectFit}
        />
      </Box>
    );
  }
};

export default CloudinaryImage;
