export interface NavLinkProps {
  name: string;
  href: string;
}

export const NAV_LINKS = [
  {
    name: 'Om oss',
    href: '/om-oss',
  },
  {
    name: 'Varumärken',
    href: '/varumarken',
  },
  {
    name: 'Annonsera',
    href: '/annonsera',
  },
  {
    name: 'TR Studio',
    href: '/tr-studio',
  },
  {
    name: 'Jobba hos oss',
    href: '/jobba-hos-oss',
  },
  {
    name: 'Aktuellt',
    href: '/aktuellt',
  },
  {
    name: 'Kontakt',
    href: '/kontakt',
  },
];

export const TERMS_AND_CONDITIONS_SLUG = 'villkor';
export const PRIVACY_POLICY_SLUG = 'integritetspolicy';
export const COOKIE_POLICY_SLUG = 'cookiepolicy';

export const LEGAL_INFO_SLUGS = [
  TERMS_AND_CONDITIONS_SLUG,
  PRIVACY_POLICY_SLUG,
  COOKIE_POLICY_SLUG,
];

export const INTERNAL_FOOTER_LINKS = [
  ...NAV_LINKS,
  { name: 'Allmänna villkor', href: `/${TERMS_AND_CONDITIONS_SLUG}` },
  { name: 'Integritetspolicy', href: `/${PRIVACY_POLICY_SLUG}` },
  { name: 'Cookiepolicy', href: `/${COOKIE_POLICY_SLUG}` },
];

export const MY_PAGES_LINK = {
  name: 'Mina sidor',
  href: 'https://minasidor.trmedia.se',
};

export const FAQ_LINK = {
  name: 'Frågor och svar',
  href: 'https://minasidor.trmedia.se/faq',
};

export const CONTACT_SUPPORT_LINK = {
  name: 'Kontakta kundtjänst',
  href: 'https://minasidor.trmedia.se/kontakt',
};

export const EXTERNAL_FOOTER_LINKS = [
  MY_PAGES_LINK,
  FAQ_LINK,
  CONTACT_SUPPORT_LINK,
];

export const whiteUnderlineHoverCss = `
  &:hover,
  &:active,
  &:focus {
    text-decoration: underline;
    color: white;
  }
  &:visited {
    color: white;
  }
`;

export const whiteOpacityHoverCss = `
  &:hover,
  &:active,
  &:focus {
    opacity: 0.9;
    color: white;
  }
  &:visited {
    color: white;
  }
`;
